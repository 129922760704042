import React from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import HeroHome from "../partials/HeroHome";
import HeroHome3 from "../partials/HeroHome3";
import Process from "../partials/Process";
import Footer from "../partials/Footer";
import Orgs from "../partials/Orgs";
import CTA from "../partials/CTA";
import WhatIf from "../partials/WhatIf";
import Algo from "../partials/Algo";
import Zen from "../partials/Zen";
import GencoinValue from "../partials/GencoinValue";

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />
      {/*  Page content */}
      <main className="grow">
        {/*  Page sections */}
        {/* {window.location.pathname === "/home-3" ? <HeroHome3 /> : <HeroHome />} */}
        <HeroHome />
        <WhatIf />
        <Process />
        <GencoinValue />
        <Algo />
        <Zen />
        <Orgs />
        <CTA />
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Home;
