import React, { useState, useRef, useEffect } from "react";
import { ORGS } from "../utils/constants";
import Modal from "../utils/Modal";

const Orgs = () => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [currentOrg, setCurrentOrg] = useState(false);

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 mb-10">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-orgs>
            <div
              className="text-3xl h4 text-white mb-4"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-orgs]"
            >
              We support some amazing organizations too...
            </div>
            <p
              className="md:text-2xl text-xl text-white font-thin"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-anchor="[data-aos-id-orgs]"
            >
              And are working to add more!
            </p>
          </div>

          <div
            className="flex flex-col items-center justify-center w-full"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <img
              className="w-full md:w-3/6 md:px-10 mb-10"
              src={require(`../images/phone/orgs-group.png`).default}
              alt="phone-orgs"
            />
          </div>

          {/* Section content */}
          <div>
            {/* Desktop and Tablet */}
            <div className="hidden md:flex flex-wrap md:justify-between justify-center">
              {ORGS.map((org) => (
                <div
                  data-aos="fade-down"
                  className="w-24 h-24 mb-3 md:p-2 px-2 cursor-pointer"
                  data-aos-delay={org.delay}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCurrentOrg(org);
                    setVideoModalOpen(true);
                  }}
                  style={{ cursor: "pointer" }}
                  aria-controls="modal"
                >
                  <img
                    className="rounded-full border-8 border-primary-3 shadow-sm"
                    src={require(`../images/logo/orgs/${org.ein}.png`).default}
                  />
                </div>
              ))}
            </div>
            {/* Mobile */}
            <div className="md:hidden grid grid-cols-3 grid-rows-2 gap-3">
              {ORGS.map((org) => (
                <div
                  data-aos="fade-down"
                  className="flex items-center justify-center w-full h-full cursor-pointer"
                  data-aos-delay={org.delay}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCurrentOrg(org);
                    setVideoModalOpen(true);
                  }}
                  style={{ cursor: "pointer" }}
                  aria-controls="modal"
                >
                  <img
                    className="w-24 h-24 mb-3 rounded-full border-8 border-primary-3 shadow-sm"
                    src={require(`../images/logo/orgs/${org.ein}.png`).default}
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            className="text-center text-primary-1 font-rubik md:text-2xl text-xl mt-6"
            data-aos="fade-up"
            data-aos-delay="2000"
          >
            Click logos above to view a quick video...
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal
        id="modal"
        ariaLabel="modal-headline"
        show={currentOrg && videoModalOpen}
        handleClose={() => {
          setCurrentOrg(null);
          setVideoModalOpen(false);
        }}
      >
        <div className="relative pb-9/16">
          <iframe
            className="absolute w-full h-full"
            src={`${currentOrg?.videoUrl}`}
            title="Video"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </section>
  );
};

export default Orgs;
