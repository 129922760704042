import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { ReactComponent as GLogo } from "../assets/gear-heart.svg";
import { ReactComponent as GenerosLogo } from "../assets/generos-logo.svg";
import Button from "../components/Button";
import { joinWaitList } from "../utils/helpers";
import GPhone from "../components/animated/GPhone";

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const history = useHistory();

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <header className="fixed bg-primary-6 w-full z-30 ">
      {/* <div className="max-w-6xl mx-auto px-4 sm:px-6"> */}
      <div className="mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="flex  font-extrabold" aria-label="Generos">
              <div className="flex items-center">
                <div className="mr-2">
                  <GLogo className="fill-current text-primary-1 w-10" />
                </div>
                <GenerosLogo className="fill-current text-white h-6" />
              </div>
              {/* <div className="text-topaz-500 font-bold ml-2 md:text-sm text-xs">
                BETA
              </div> */}
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow md:justify-center">
            {/* Desktop menu links */}

            {/* Desktop sign in links */}
            <ul className="flex grow justify-end flex-wrap font-extrabold items-center mr-8">
              <li>
                <Link
                  to="/"
                  className="text-lilac-500 hover:text-lilac-400 px-8 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/manifesto"
                  className="text-lilac-500 hover:text-lilac-400 px-8 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  The Generos Manifesto
                </Link>
              </li>
              <li>
                <Link
                  to="/community-values"
                  className="text-lilac-500 hover:text-lilac-400 px-8 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Community Values
                </Link>
              </li>
              <li>
                <a
                  href={process.env.REACT_APP_GENEROS_DOCS_URI}
                  target="_blank"
                  rel="noreferrer"
                  className="text-lilac-500 hover:text-lilac-400 px-8 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Learn More
                </a>
              </li>
              <li>
                <Button
                  className="mt-8 mb-4"
                  variant="primary-w"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_GENEROS_APP_URI}/install`,
                      "_blank"
                    );
                  }}
                >
                  <div className="flex items-center">
                    <GPhone className="fill-current text-white w-12" />
                  </div>
                </Button>
              </li>
            </ul>
          </nav>

          {/* Mobile menu */}
          <div className="md:hidden">
            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && "active"}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-lilac-500 hover:text-lilac-400 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <nav
              id="mobile-nav"
              ref={mobileNav}
              className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
              style={
                mobileNavOpen
                  ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 }
                  : { maxHeight: 0, opacity: 0.8 }
              }
            >
              <ul className="bg-primary-6 px-4 py-2">
                <li>
                  <Link
                    to="/"
                    className="text-lilac-200 font-bold hover:text-lilac-400 px-8 py-2 flex items-center transition duration-150 ease-in-out"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/manifesto"
                    className="text-lilac-200 font-bold hover:text-lilac-400 px-8 py-2 flex items-center transition duration-150 ease-in-out"
                  >
                    The Generos Manifesto
                  </Link>
                </li>
                <li>
                  <Link
                    to="/community-values"
                    className="text-lilac-200 font-bold hover:text-lilac-400 px-8 py-2 flex items-center transition duration-150 ease-in-out"
                  >
                    Community Values
                  </Link>
                </li>
                <li>
                  <a
                    href={process.env.REACT_APP_GENEROS_DOCS_URI}
                    target="_blank"
                    rel="noreferrer"
                    className="text-lilac-200 font-bold hover:text-lilac-400 px-8 py-2 flex items-center transition duration-150 ease-in-out"
                  >
                    Learn More
                  </a>
                </li>
                <li>
                  <Button
                    className="mt-8 mb-4"
                    variant="primary-w"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_GENEROS_APP_URI}/install`,
                        "_blank"
                      );
                    }}
                  >
                    <div className="flex items-center">
                      <GPhone className="fill-current text-white w-12" />
                      <div className="flex flex-col text-left ml-4">
                        <div className="text-xl font-extrabold text-white">
                          Get the App
                        </div>
                        <div className="text-lg italic">
                          Join the Generos Community
                        </div>
                      </div>
                    </div>
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
